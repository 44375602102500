<template>
    <div class="my">
        <Head :type="1" :backType="1"></Head>
        <div class="main">

            <div class="person" v-if="info" @click="changeColor">
                <img class="headimg" :src="info.headimgUrl" alt="">
                <div class="box"><div class="name">{{info.nickName}}</div> <div class="shop-type"  v-if="info.shopType!==null">{{list[info.shopType]}}创客商家</div>
                <div class="shop-type"  v-if="info.shopType==null">暂未开通创客店铺</div></div>
               

            </div>
            <div class="item">
                <div class="warp">
                <div class="order">我的订单</div>
                <div class="right" @click="$router.push('makerOrder')">查看更多 <img src="../../../assets/img/right_gery.png" alt="" ></div>
                </div>

                <van-grid icon-size="0.666667rem" :border="false"  :column-num="5">
                <van-grid-item   :icon="require('../../../assets/maker/waitPay.png')" @click="$router.push({path:'/makerOrder',query:{type:1}})" text="待付款" />
                <van-grid-item :icon="require('../../../assets/maker/car-c.png')" text="待发货"  @click="$router.push({path:'/makerOrder',query:{type:2}})"   />
                <van-grid-item :icon="require('../../../assets/maker/daishouhuo.png')" text="待收货"  @click="$router.push({path:'/makerOrder',query:{type:3}})"  />
                <van-grid-item :icon="require('../../../assets/maker/chat-o.png')" text="待评价"  @click="$router.push({path:'/makerOrder',query:{type:4}})"  />
                <van-grid-item :icon="require('../../../assets/maker/why.png')" text="退款/退货"   @click="$router.push({path:'/makerAfterSale',query:{type:5}})" />
                </van-grid>
            </div>
             <div class="item" v-if="info">
                <div class="warp" @click="close">
                <div class="order" >收益报表</div>
                <div class="right">收益详情 <img src="../../../assets/img/right_gery.png" alt=""></div>
                </div>

              <div class="box">
                  <div>
                      <div class="title">{{info.todayCount}}</div>
                      <span>今日营收(笔)</span>
                  </div>
                  <div>
                      <div class="title">{{info.yesterdayCount}}</div>
                      <span>昨日营收(笔)</span>
                  </div>
                  <div>
                      <div class="title">{{info.monthCount}}</div>
                      <span>本月营收(元)</span>
                  </div>
              </div>
            </div>

            <div class="item">
                <div class="warp">
                <div class="order">常用工具</div>
                <div class="right">查看更多 <img src="../../../assets/img/right_gery.png" alt=""></div>
                </div>
              <van-grid icon-size="0.666667rem" :border="false"  :column-num="5">
                <van-grid-item   :icon="require('../../../assets/maker/zhuangxiu.png')" text="店铺装修" />
                <van-grid-item :icon="require('../../../assets/maker/kefu.png')" text="联系客服" />
                </van-grid>
            </div>
        </div>
    </div>
</template>

<script>
import Head from '../../../components/head/index.vue'
import{ getInfo} from '../../../api/maker/myInfo'
    export default {
        data(){ 
            return{ 
                        info:null,
                        list:['个人','店铺','企业']
            }
        },
        created(){ 
            getInfo().then(res=>{ 
                if(res.data.code===0){ 
                    this.info=res.data.data
                    
                }
            })
            // 
        },
        methods:{ 
            changeColor(){ 
                window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({color:'#e74a45'}))
                // window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({img:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.2008php.com%2F09_Website_appreciate%2F10-07-11%2F1278861720_g.jpg&refer=http%3A%2F%2Fwww.2008php.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638329945&t=080e48a945337de99123e69da33c03f0'}))

            
            },
            close(){ 
                window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({}))
            }
        },
        components:{
            Head
        }
    }
</script>

<style lang="less" scoped>
.my{
    height: 100%;
    background-image: url("../../../assets/img/red_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    font-family: PingFangSC-Regular, PingFang SC;
    background-color: #f6f6f6;
    .main{
        padding:.5rem .426667rem  0;
        height: 100%;
   
    .person{
        display: flex;
        align-items: center;
        color: white;
        margin-bottom: .56rem;
        .headimg{
            width: 1.946667rem;
            height: 1.946667rem;
            border-radius: 50%;
            margin-right: .266667rem;
        }
        .name{
            font-size: .533333rem;
            margin-bottom: .186667rem;
            
        }
        .shop-type{
            font-size: .373333rem;
        }
    }
    .item{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: .266667rem;
        background-color: white;
        padding:.266667rem;
        margin-bottom: .266667rem;
        .warp{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: .373333rem;
            padding-bottom: .266667rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .order{
            color: #333333;
            }
            .right{
                display: flex;
                align-items: center;
                color:#999;
                img{
                    width:.426667rem;
                    height: .426667rem;
                    margin-left: .133333rem;
                }
            }
        }
       /deep/ .van-grid-item__text{
           font-size: .32rem;   
           transform: scale(0.83);
            color: rgba(0, 0, 0, 0.85);
        }
        /deep/ .van-grid-item__content{
            padding: .453333rem 0 .133333rem;
        }
        .box{
            display: flex;
            justify-content: space-between;
            padding: .373333rem 0 .133333rem;
            div{
                text-align: center;
                 color: rgba(0, 0, 0, 0.85);
            }
            .title{
                font-size: .373333rem;
                margin-bottom: .373333rem;
               
                font-family: JLinXin;
            }
            span{
                font-size: .32rem;
                transform: scale(0.83);
            }
        }
    }
 }
}

</style>