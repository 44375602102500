<template>
<div class="maker" >
  <div class="list-area">
    <Car v-if="active===1"></Car>
    <My v-if="active===2"></My>
    
    <List v-if="active===0"></List>
   
  </div>
     
            <div :class="{'shad':active!==1}">
          <van-tabbar
              v-model="active"
              active-color="#FF7200"
              inactive-color="#000"
              safe-area-inset-bottom
              :fixed="false"
            >
              <van-tabbar-item  v-if="active!==0" :icon="require('../../assets/maker/maker_list.png')" 
                >列表</van-tabbar-item
              >
              <van-tabbar-item  v-if="active===0" :icon="require('../../assets/maker/maker_list_c.png')" 
                >列表</van-tabbar-item
              >
              <van-tabbar-item v-if="active!==1" :icon="require('../../assets/maker/maker_car.png')" 
                >购物车</van-tabbar-item
              >
              <van-tabbar-item v-if="active==1" :icon="require('../../assets/maker/maker_car_c.png')" 
                >购物车</van-tabbar-item
              >
              <van-tabbar-item  v-if="active!==2" :icon="require('../../assets/maker/maker_my.png')" 
                >我的</van-tabbar-item
              >
              <van-tabbar-item   v-if="active==2"  :icon="require('../../assets/maker/maker_my_c.png')"
                >我的</van-tabbar-item
              >
            </van-tabbar>
            </div>
    </div>
</template>

<script>
import Car from './shoppingCart/index'
import My from './my/index'
import List from './list/index'
    export default {
      // name:'makerCenter',
                data(){

                    return{

                        active:0
                        
                    }
                    
                },
        created(){ 
           let session =this.$route.query.session || "73b4f5d5-b4fd-4247-ab08-d1b0bdecd424";
    this.$store.commit("setSession", session);
        },
         mounted() {
    this.setRem();
  },
  components:{ 
    Car,
    My,
    List
  },
  beforeDestroy() {
    document.body.style.fontSize = "16px";
  },
    }
</script>

<style lang="less" scoped>
@import "../../assets/font/font_2874283_io65qsf5br/iconfont.css";
.maker{
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    .shad{
       box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .list-area{
      flex: 1;
      box-sizing: border-box;
      overflow: hidden;
    }
}

</style>
