import { fetchGet} from   "../../router/https.js"
function cateList(){ 
    return fetchGet('createrapp/mallcategoryconfig/list')
}

function getGoods(data){ 
    return fetchGet('/createrapp/storeproductinfo/page',data)
}

export { 
    cateList,
    getGoods
}