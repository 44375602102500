<template>

  <div class="list">
    <Head :type="0" :backType="1">
      <template #title>
        <div>创客商城</div>
      </template>
      <template #right>
        <div class="open">我要开店</div>
      </template>
    </Head>

    <van-tabs
      color="#FF5200"
      line-width="0.266667rem"
      line-height="0.08rem"
      title-active-color="#FF5200"
      title-inactive-color="#333333"
      size="0.426667rem"
       @click="change"
    >
      <van-tab v-for="item in list" :title="item.name" :key="item.id"> </van-tab>
    </van-tabs>

    <div class="main">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onload"
        :immediate-check="false"
        :offset-top="30"
      >
        <div class="box" >
          <div class="item" v-for=" item in goodsDate" :key="item.skuId" @click="$router.push({path:'makerGoodsDetail',query:{id:item.id}})">
            <img :src="item.images[0]" alt="" />
              <div class="name">{{item.subject}}</div>
            <div class="shop-name">{{item.storeName}}</div>
            <div class="price"><span>￥</span>{{ item.salePrice||'0'}}</div>
            <div class="readsell">月售{{item.saleNum||'0'}}万</div>
          </div>  
        </div>
      </van-list>
    </div>
  </div>
 
</template>

<script>
import Head from "../../../components/head/index.vue";
import { cateList, getGoods } from "../../../api/maker/shop";
// 
export default {
  name:'list',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      active:0,
      goodsDate:[],
      page:1,
      size:20,
      id:''
    };
  },
  created() {
    this.$store.commit('setSession' , '91ad1b43-2652-4270-bbd1-ade630fc9622')
    this.getDate();
  },
  methods: {
    getDate() {
      // 获取分类列表
      cateList().then((res) => {

        if (res.data.code === 0) {

          this.list = res.data.data;

            this.id = this.list[0].id
            this.goodsList()
        }

      });

    },
    onload(){ 
      this.page++,
      this.goodsList()
    },
    change(item){ 
      console.log(item);
      this.goodsDate=[]
            this.id= this.list[item].id
            this.page=1
            this.goodsList()
    },
      goodsList(){ 
       getGoods({categoryId:this.id,current:this.page,size:this.size}).then(res=>{ 
           if(res.data.code===0){ 
               this.goodsDate=[...this.goodsDate,...res.data.data.records]
              
                  this.finished=false
               
           }
           if(this.goodsDate.length===res.data.data.total){ 
                this.finished=true
                }
           this.loading=false
       })
  },
  },


  components: {
    Head,
  },
  destroyed(){ 
    document.body.style.fontSize="16px"
  }
};
</script>

<style lang="less" scoped>
.open {
  text-align: center;
  font-family: SFProDisplay-Regular, SFProDisplay;

  font-size: 0.453333rem;

  color: #000000;
}
.list {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .main {
    background-color: #f6f6f6;
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
    padding-bottom: 0.133333rem;

    .van-list {
      height: 100%;
      overflow: auto;
      // height: 500px;

      .box {
        padding: 0.266667rem 0;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        width: 100%;

        .item {
          background-color: white;
          border-radius: 0.266667rem;
          box-sizing: border-box;
          width: 4.72rem;
          position: relative;
          padding: 0.213333rem 0.293333rem 0.16rem;
          height: auto;
          margin: 0 .133333rem .266667rem;
          img {
            border-radius: 0.133333rem;
            width: 4.133333rem;
            height: 4.266667rem;
          }
          .name {
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: 0.373333rem;
            color: #333333;
            margin-bottom: 0.266667rem;
          }
          .shop-name {
            color: #999999;
            font-size: 0.32rem;
            margin-bottom: 0.213333rem;
          }
          .price {
            color: #a80000;
            font-family: PingFangSC-Medium, PingFang SC;
            font-size: 0.48rem;
            span {
              font-size: 0.373333rem;
            }
          }
          .readsell {
            color: #999999;
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            position: absolute;
            right: 0.293333rem;
            bottom: 0.186667rem;
          }
        }
      }
    }
  }
}
</style>